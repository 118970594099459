var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_c('div',{staticClass:"content-header"},[_c('div',{staticClass:"container-fluid"},[_vm._m(0),_c('div',{staticClass:"row"},[_vm._m(1),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"card"},[_vm._m(2),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('form',{attrs:{"action":"https://www.usawaterski.org/rankings/collegiate/ncwsa_rostermanager_usa.asp","method":"post","target":"_blank"}},[_c('input',{attrs:{"type":"hidden","name":"id"},domProps:{"value":_vm.ls.id}}),_c('input',{attrs:{"type":"hidden","name":"auth"},domProps:{"value":_vm.ls.auth}}),_vm._m(3)]),_c('form',{staticClass:"mt-3",attrs:{"action":"https://www.usawaterski.org/rankings/collegiate/ncwsa_waiver_usa.asp?FormStatus=List","method":"post","target":"_blank"}},[_c('input',{attrs:{"type":"hidden","name":"id"},domProps:{"value":_vm.ls.id}}),_c('input',{attrs:{"type":"hidden","name":"auth"},domProps:{"value":_vm.ls.auth}}),_vm._m(4)]),_c('form',{staticClass:"mt-3",attrs:{"action":"https://www.usawaterski.org/rankings/collegiate/ncwsa_registrar_usa.asp","method":"post","target":"_blank"}},[_c('input',{attrs:{"type":"hidden","name":"id"},domProps:{"value":_vm.ls.id}}),_c('input',{attrs:{"type":"hidden","name":"auth"},domProps:{"value":_vm.ls.auth}}),_vm._m(5)])])])])])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row mb-3"},[_c('div',{staticClass:"col-md-12"},[_c('h1',{staticClass:"m-0 text-dark"},[_vm._v("Collegiate Links")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('h2',{staticClass:"card-title"},[_vm._v("Using Local Storage")])]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('a',{staticClass:"btn btn-primary btn-blue btn-block",attrs:{"href":"https://www.usawaterski.org/rankings/collegiate/ncwsa_rostermanager_usa.asp","target":"_blank"}},[_vm._v(" Team Captain Tools "),_c('i',{staticClass:"fa fa-arrow-right ml-2"})]),_c('a',{staticClass:"mt-3 btn btn-primary btn-blue btn-block",attrs:{"href":"https://www.usawaterski.org/rankings/collegiate/ncwsa_waiver_usa.asp?FormStatus=List","target":"_blank"}},[_vm._v(" Individual Event Waivers "),_c('i',{staticClass:"fa fa-arrow-right ml-2"})]),_c('a',{staticClass:"mt-3 btn btn-primary btn-blue btn-block",attrs:{"href":"https://www.usawaterski.org/rankings/collegiate/ncwsa_registrar_usa.asp","target":"_blank"}},[_vm._v(" Registrar Tools "),_c('i',{staticClass:"fa fa-arrow-right ml-2"})])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header"},[_c('h2',{staticClass:"card-title"},[_vm._v("Using POST")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"btn btn-primary btn-blue btn-block"},[_vm._v(" Team Captain Tools "),_c('i',{staticClass:"fa fa-arrow-right ml-2"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"btn btn-primary btn-blue btn-block"},[_vm._v(" Individual Event Waivers "),_c('i',{staticClass:"fa fa-arrow-right ml-2"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"btn btn-primary btn-blue btn-block"},[_vm._v(" Registrar Tools "),_c('i',{staticClass:"fa fa-arrow-right ml-2"})])
}]

export { render, staticRenderFns }